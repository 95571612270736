@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

// Set your brand colors
$purple: #C7CEEA;
$pink: #FFB7B2;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
$green: #B5EAD7;
$blue: #C7CEEA;
$yellow: #E2F0CB;
$orange: #FF9AA2;
// Update Bulma's global variables
$family-sans-serif: 'Nunito', sans-serif;
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $pink;
$link: $brown;
$success: $green;
$info: $blue;
$warning: $yellow;
$danger: $orange;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $beige-lighter;
$footer-background-color: $beige-lighter;
$box-background-color: $beige-light;
$control-border-width: 2px;
$input-border-color: $beige-light;
$input-shadow: none;
$input-placeholder-color: $brown;

// Import only what you need from Bulma
@import '../node_modules/bulma/sass/utilities/_all.sass';
@import '../node_modules/bulma/sass/base/_all.sass';
@import '../node_modules/bulma/sass/elements/_all.sass';
@import '../node_modules/bulma/sass/components/navbar.sass';
@import '../node_modules/bulma/sass/layout/hero.sass';
@import '../node_modules/bulma/sass/layout/section.sass';
@import '../node_modules/bulma/sass/components/card.sass';
@import '../node_modules/bulma/sass/grid/_all.sass';
@import '../node_modules/bulma/sass/elements/image.sass';
@import '../node_modules/bulma/sass/components/media.sass';
@import '../node_modules/bulma/sass/form/_all.sass';
@import '../node_modules/bulma/sass/layout/footer.sass';
@import '../node_modules/bulma-extensions/bulma-divider/src/sass/index.sass';
